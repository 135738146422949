<template>
	<main class="layer-popup-container">
		<div class="layer-popup-inquiry">
			<div>
				<div class="popup-wrapper">
					<header class="popup-header">
						<h3 class="header-title">중소기업(소상공인) 확인서 정보 확인</h3>
						<button type="button" class="btn-close" @click="clickClose">
							<i class="icon-close">팝업닫기</i>
						</button>
					</header>
					<div class="popup-body" data-fill="true">
						<div class="box-round">
							<i class="icon-reserv-check"></i><strong>정보조회에 실패했습니다.</strong><br />
							확인서 이미지 파일을 업로드해 주세요.
						</div>
						<div class="box-info modal-reserv-boxinfo">
							<i class="icon-reserv-info"></i>
							<p>「중소기업법」 제2조에 따른 중소기업 여부를 확인하기 위한 정보로서 현재 기준 유효한 중소기업(소상공인) 확인서를 발급받은 기업만 조회 가능합니다. (발급처 :siminfo.mss.go.kr)</p>
						</div>
						<file-upload ref="fileUpload" />
					</div>
					<div class="popup-buttons">
						<button type="button" class="button-default is-primary" @click="fileUploadClick">파일등록</button>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import { ACT_UPLOAD_SPACE_RSVT_IDENTITY } from '@/store/_act_consts';
import { MUT_SHOW_ALERT, MUT_SET_SPACE_RSVT_BZMNCNFM } from '@/store/_mut_consts';
import { getItem, isSuccess } from '@/assets/js/utils';
import { mapGetters } from 'vuex';
import FileUpload from '@/components/common/FileUpload';

export default {
	name: 'ReservSBCheckModal',
	components: {
		FileUpload,
	},
	computed: {
		...mapGetters('space', ['rsvtTimes', 'bzmnCnfm']),
	},
	data: () => ({}),
	props: {
		brno: String,
		cmpyNm: String,
	},
	created() {},
	mounted() {},
	methods: {
		clickClose() {
			this.$emit('close');
		},
		uploadIdnty() {
			// console.log(this.$refs.fileUpload.file);
			// console.log(this.$refs.fileUpload.$refs.fileUpload.files);
			if (this.$refs.fileUpload.$refs.fileUpload.files && this.$refs.fileUpload.$refs.fileUpload.files.length > 0) {
				this.$store.dispatch(`space/${ACT_UPLOAD_SPACE_RSVT_IDENTITY}`, this.$refs.fileUpload.$refs.fileUpload.files).then((res) => {
					if (isSuccess(res.data)) {
						const item = getItem(res.data);
						// console.log(item.fileName);
						// console.log(item.uploadKey);
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: '소상공인확인서가 업로드 되었습니다.',
							html: true,
							yesfunc: () => {
								this.uploadCommit(item);
							},
						});
					} else {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '오류가 발생했습니다. 다시 시도해주세요.' });
					}
					//this.$refs.idnty.value = '';
				});
			}
		},
		uploadCommit(item) {
			const rsvtStateParam = {
				brno: this.brno,
				cmpyNm: this.cmpyNm,
				bzmnCnfmDocCnfmYn: '미확인',
				bzmnCnfmDocUrl: item.uploadKey,
				bzmnCnfmDocNm: item.fileName,
			};

			this.$store.commit(`space/${MUT_SET_SPACE_RSVT_BZMNCNFM}`, rsvtStateParam);
			this.$emit('close');
		},
		fileUploadClick() {
			// console.log(this.$refs.fileUpload.fileName);
			this.uploadIdnty();
		},
	},
};
</script>
