<template>
	<main class="page-container" id="reservation-form">
		<reserv-page-header :title="roomInfo.spaceNm" />

		<div class="page-component">
			<div class="box-gray">
				<strong>{{ this.roomInfo.spaceNm }}<br />{{ this.roomInfo.roomNm }}</strong> {{ rsvtDayString }} <br />
				{{ rsvtTimesString }}
			</div>
			<!-- s: 가입 -->
			<div class="reserv-forms">
				<div>
					<!-- s: 예약자 정보 -->
					<fieldset class="fieldset-section">
						<div class="fieldset-header">
							<legend class="fieldset-legend">예약자 정보</legend>
							<span class="fieldset-reference">
								<em class="required">필수</em>
								<span class="text">필수정보</span>
							</span>
						</div>
						<div class="fieldset-content">
							<div class="field field-row">
								<div class="field-label w160"><label for="">예약자명</label><em class="required">필수</em></div>
								<div class="control field-form">
									<input v-model="params.rsvtUserNm" type="text" class="input" required="required" />
								</div>
							</div>
							<div class="field field-row">
								<div class="field-label w160"><label for="">연락처</label><em class="required">필수</em></div>
								<div class="control field-form">
									<input v-model="params.cnctelNo" type="tel" class="input" required="required" />
								</div>
							</div>
							<div class="field field-row">
								<div class="field-label w160"><label for="">이메일</label><em class="required">필수</em></div>
								<div class="control field-form">
									<input v-model="params.email" type="email" class="input" required="required" />
								</div>
							</div>
						</div>
					</fieldset>
					<!-- e: 예약자 정보 -->
					<!-- s: 기업 정보 -->
					<fieldset class="fieldset-section">
						<div class="fieldset-header">
							<legend class="fieldset-legend">기업정보</legend>
							<span class="fieldset-reference">
								<em class="required">필수</em>
								<span class="text">필수정보</span>
							</span>
						</div>
						<div class="fieldset-content">
							<div class="field field-row">
								<div class="field-label w160"><label for="">기업명</label><em class="required">필수</em></div>
								<div class="control field-form">
									<input v-model="params.cmpyNm" type="text" class="input" required="required" :readonly="cmpyCheck" />
								</div>
							</div>
							<div class="field field-row">
								<div class="field-label w160"><label for="">사업자번호</label><em class="required">필수</em></div>
								<div class="control field-form">
									<input v-model="params.brno" type="tel" class="input" required="required" :readonly="cmpyCheck" />
								</div>
							</div>
							<div class="field field-row">
								<div class="field-label w160"><label for="">중소기업(소상공인) 확인서 정보 확인</label><em class="required">필수</em></div>
								<div class="control field-form flex-0-0 text-right mobile-full">
									<button v-if="!cmpyCheck" type="button" class="button-default is-medium is-secondary is-rounded" @click="getRsvtCmpyValidation">정보조회</button>

									<div v-if="cmpyCheck" class="display-block">
										<div v-if="cmpyCheck2" class="box-gray"><i class="icon-reserv-check"></i>확인서 파일이 등록되었습니다.</div>
										<!-- <div v-else-if="cmpyCheck3" class="box-gray"><i class="icon-reserv-check"></i>정보조회에 성공하였습니다.
									<p v-if="bzmnCnfm.issueDate" >등록일 &nbsp;&nbsp;&nbsp;&nbsp;: {{ bzmnCnfm.issueDate }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
									<p v-if="bzmnCnfm.periodStrtDate">유효기간 : {{ bzmnCnfm.periodStrtDate }} ~ {{ bzmnCnfm.periodEndDate }}</p>
								</div> -->
										<div v-else-if="cmpyCheck3" class="box-gray is-checked-mb0">
											<div class="flex align-items-center justify-content-center text-center"><i class="icon-reserv-check"></i><strong>정보조회에 성공하였습니다.</strong></div>
											<div class="box-gray-footer">
												<span v-if="bzmnCnfm.issueDate">등록일 : {{ bzmnCnfm.issueDate }}</span>
												<span v-if="bzmnCnfm.periodStrtDate">유효기간 : {{ bzmnCnfm.periodStrtDate }} ~ {{ bzmnCnfm.periodEndDate }}</span>
											</div>
										</div>
										<div v-else-if="cmpyCheck1" class="box-gray is-checked-mb0"><i class="icon-reserv-check"></i>확인 완료 상태입니다.</div>
										<!-- <div class="box-info flex">
									<i class="icon-reserv-info"></i>
									<p>「중소기업법」 제2조에 따른 중소기업 여부를 확인하기 위한 정보로서 현재 기준 유효한 중소기업(소상공인) 확인서를 발급받은 기업만 조회 가능합니다. (발급처 :siminfo.mss.go.kr)</p>
								</div> -->
									</div>
								</div>
							</div>

							<div class="field field-row">
								<div class="field-label w160"><label for="">기업 특성</label><em class="required">필수</em></div>
								<div class="control field-form">
									<select v-model="params.cmpyType" class="form-select">
										<option value="해당없음">해당없음</option>
										<option value="여성기업">여성기업</option>
										<option value="사회적기업">사회적기업</option>
										<option value="장애인기업">장애인기업</option>
									</select>
								</div>
							</div>
						</div>
					</fieldset>
					<!-- e: 기업 정보 -->
					<!-- s: 대표 제품정보 -->
					<fieldset class="fieldset-section">
						<div class="fieldset-header">
							<legend class="fieldset-legend">대표 제품정보</legend>
							<span class="fieldset-reference">
								<em class="required">필수</em>
								<span class="text">필수정보</span>
							</span>
						</div>
						<div class="fieldset-content">
							<div class="field field-row">
								<div class="field-label w160"><label for="">제품분류</label><em class="required">필수</em></div>
								<div class="control field-form">
									<select v-model="params.manprdClsDcd" class="form-select">
										<option value="">선택하세요.</option>
										<option v-for="(manprd, idx) in spaceRsvtManprdClsDcds" :key="idx" :value="manprd.dcd">{{ manprd.dcdVal }}</option>
									</select>
								</div>
							</div>
							<div class="field field-row">
								<div class="field-label w160"><label for="">제품명</label><em class="required">필수</em></div>
								<div class="control field-form">
									<input v-model="params.rprsManprdNm" type="text" class="input" required="required" />
								</div>
							</div>
						</div>
					</fieldset>
					<!-- e: 대표 제품정보 -->
					<!-- s: 예약정보 -->
					<fieldset class="fieldset-section">
						<div class="fieldset-header">
							<legend class="fieldset-legend">예약정보</legend>
						</div>
						<div class="fieldset-content">
							<div class="field field-row">
								<div class="field-label w160"><label for="">예약목적</label><em class="required">필수</em></div>
								<div class="control field-form">
									<select v-model="params.rsvtPurposDcd" class="form-select">
										<option value="">선택하세요</option>
										<option v-for="(purpose, idx) in spaceRsvtPurposDcds" :key="idx" :value="purpose.dcd">{{ purpose.dcdVal }}</option>
									</select>
								</div>
							</div>
							<div class="field field-row">
								<div class="field-label w160"><label for="">방문자수</label><em class="required">필수</em></div>
								<div class="control field-form">
									<input v-model="params.vstrCnt" type="number" min="0" :max="roomInfo.maxRsvtUsrcnt" class="input" placeholder="숫자를 입력하세요." required="true" @change="vstrCntChange" />
								</div>
							</div>
							<div class="field field-row 1">
								<div class="field-label w160"><label for="">대여장비</label><em class="required">필수</em></div>
								<div class="control field-form field-checkbox-wrap">
									<div class="form-checkbox is-round" v-for="(option, idx) in spaceRoomItemDcds" :value="option.dcd" :key="idx">
										<input type="checkbox" :id="`${option.dcd}`" name="rsvtSelectedItems" :value="`${option.dcd}`" v-if="roomInfo.itemDcdList.indexOf(option.dcd) != -1" />
										<label :for="`${option.dcd}`" v-if="roomInfo.itemDcdList.indexOf(option.dcd) != -1">{{ option.dcdVal }}</label>
									</div>
								</div>
							</div>
							<div class="field field-row">
								<div class="field-label w160"><label for="">추가대여공간</label></div>
								<div class="control field-form">
									<select v-model="params.addRoomId" class="form-select">
										<option value="0">없음</option>
										<option v-for="(room, idx) in roomList" :key="idx" :value="room.roomId" v-if="room.roomId != roomId">{{ room.roomNm }}</option>
									</select>
								</div>
							</div>
							<div class="field field-row">
								<div class="field-label w160"><label for="">기타 요청사항</label></div>
								<div class="control field-form">
									<textarea v-model="params.rqstCn" class="input textarea" rows="5" placeholder="내용을 입력하세요."></textarea>
								</div>
							</div>
						</div>
					</fieldset>
					<!-- e: 예약정보 -->

					<div class="buttons flex justify-content-center">
						<button class="button-default is-large is-primary is-rounded" @click="clickSave">예약신청</button>
					</div>
				</div>
			</div>
			<!-- e: 가입 -->
			<reserv-sb-check-modal :brno="params.brno" :cmpyNm="params.cmpyNm" v-if="reservSbCheck.show" @close="popClose" />
		</div>
	</main>
</template>

<script>
import ReservSbCheckModal from '@/components/space/SBCheckModal';
import ReservPageHeader from '@/components/space/PageHeader';
import { ACT_GET_COMMON_CODE_LIST, ACT_GET_STUDIO_RESERVATION_SCHEDULE_LIST, ACT_GET_STUDIO_ROOM, ACT_GET_SPACE_ROOM_INFO, ACT_GET_SPACE_RSVT_SCHD_LIST, ACT_GET_SPACE_RSVT_CMPY_CHECK, ACT_GET_SPACE_ROOM_LIST, ACT_INSERT_SPACE_RSVT_INFO } from '@/store/_act_consts';
import { collectionCheck, getItem, getCheckItems, getItems, lengthCheck, isSuccess, getResult, timestampToDateFormat } from '@/assets/js/utils';
import { MUT_SHOW_ALERT, MUT_SHOW_CONFIRM, MUT_SHOW_RESERVATION_MODAL, MUT_SET_RETURN_ROUTE, MUT_SET_SPACE_RSVT_TIMES, MUT_SET_SPACE_RSVT_BZMNCNFM } from '@/store/_mut_consts';
import { mapGetters } from 'vuex';
import { getTokenByKey } from '@/assets/js/jwt.service';

export default {
	name: 'SpaceRsvtInput',
	components: {
		ReservPageHeader,
		ReservSbCheckModal,
	},
	watch: {
		bzmnCnfm: {
			handler(newVal, oldVal) {
				this.params.brno = newVal.brno;
				this.params.cmpyNm = newVal.cmpyNm;
				this.bzmnCnfmDocCnfmYn = newVal.bzmnCnfmDocCnfmYn;
				this.params.bzmnCnfmDocCnfmYn = newVal.bzmnCnfmDocCnfmYn;
				this.bzmnCnfmDocUrl = newVal.bzmnCnfmDocUrl;
				this.params.bzmnCnfmDocUrl = newVal.bzmnCnfmDocUrl;
				this.bzmnCnfmDocNm = newVal.bzmnCnfmDocNm;
				this.params.bzmnCnfmDocNm = newVal.bzmnCnfmDocNm;
			},
			immediate: true,
		},
		rsvtTimes: {
			handler(newVal, oldVal) {
				if (newVal.rsvtDt) {
					this.params.rsvtDt = newVal.rsvtDt;
					newVal.rsvtTimeList.forEach((element) => {
						const param = { rsvtBgngHhmm: element.rsvtBgngHhmm, rsvtEndHhmm: element.rsvtEndHhmm };
						this.params.rsvtTimeList.push(param);
					});
				} else {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: '예약일자 선택이 필요합니다.',
						html: true,
						yesfunc: () => {
							this.$router.push({ name: 'SpaceRsvt', params: { spaceId: this.params.spaceId, roomId: this.params.roomId } });
						},
					});
				}
			},
			immediate: true,
		},
		session: {
			handler(newVal, oldVal) {
				this.params.rsvtUserNm = this.session.userNm;
				this.params.cnctelNo = this.session.mblNo;
				this.params.email = this.session.userEmail;

				this.params.brno = this.session.prtlBrno;
				this.params.cmpyNm = this.session.compNm;
			},
			immediate: true,
		},
	},
	data: () => ({
		reservSbCheck: {
			show: false,
		},
		roomInfo: {
			roomId: 0,
			spaceId: 0, // 공간_ID
			roomNm: '', // 룸명
			maxRsvtUsrcnt: 0, // 최대 예약 인원
			operMemo: '', // 운영 시간
			spaceImpsbTimeUseYn: '', // 공간이용불가시간사용여부(공간과동일)
			itemDcdList: '', // 품목 DCD 리스트
			itemEtcArcCn: '', // 품목기타항목 내용
			intro: '', // 소개글
			introCn: '', // 소개글
			esntlCnfmCaseCn: '', // 필수확인사항
			useYn: '',
			impsbTimeDomains: [],
			glryDtlDomains: [],
			spaceNm: '',
			rprsImgFileUrl: '',
			operStrtHhmm: '',
			operEndHhmm: '',
		},

		roomList: [],

		params: {
			spaceId: 0,
			roomId: 0,
			rsvtDt: '',
			rsvtUserId: 0,
			rsvtUserNm: '',
			rsvtType: '플랫폼',
			cnctelNo: '',
			email: '',
			brno: '',
			bzmnCnfmDocCnfmYn: '미확인',
			bzmnCnfmDocUrl: '',
			bzmnCnfmDocNm: '',
			rsvtPurposDcd: '',
			addRoomId: 0,
			itemDcdList: '',
			itemEtcArcCn: '',
			vstrCnt: 0,
			manprdClsDcd: '',
			rprsManprdNm: '',
			cmpyNm: '',
			cmpyType: '해당없음',
			rqstCn: '',
			rsvtStsDcd: '',
			mngrMemoCn: '',
			rsvtId: 0,
			rsvtTimeList: [],
		},
		spaceRsvtStatusDcds: [],
		spaceRsvtPurposDcds: [],
		spaceTypeDcds: [],
		spaceRsvtManprdClsDcds: [],
		spaceRoomItemDcds: [],

		bzmnCnfmDocCnfmYn: '미확인',
		bzmnCnfmDocUrl: '',
		bzmnCnfmDocNm: '',
		bzmnCnfmChkBtnClk: false,
	}),
	computed: {
		...mapGetters('auth', ['session', 'checkMeInfo']),
		...mapGetters('space', ['rsvtTimes', 'bzmnCnfm']),
		roomName() {
			const result = [];
			if (this.roomInfo.spaceNm) result.push(this.roomInfo.spaceNm);
			if (this.roomInfo.roomNm) result.push(this.roomInfo.roomNm);
			return result.join(' — ');
		},
		rsvtDayString() {
			return `${this.rsvtTimes.rsvtDt}(${this.rsvtTimes.rsvtDayWeek})`;
		},
		rsvtTimesString() {
			let str = '';
			this.rsvtTimes.rsvtTimeList.forEach((element) => {
				if (str === '') {
					str = `${element.rsvtBgngHhmm.substring(0, 2)}:${element.rsvtBgngHhmm.substring(2, 4)} ~ ${element.rsvtEndHhmm.substring(0, 2)}:${element.rsvtEndHhmm.substring(2, 4)}(${Math.floor(element.min / 60) ? `${Math.floor(element.min / 60)}시간` : ''}${element.min % 60 ? '30분' : ''})`;
				} else {
					str += `,  ${element.rsvtBgngHhmm.substring(0, 2)}:${element.rsvtBgngHhmm.substring(2, 4)} ~ ${element.rsvtEndHhmm.substring(0, 2)}:${element.rsvtEndHhmm.substring(2, 4)}(${Math.floor(element.min / 60) ? `${Math.floor(element.min / 60)}시간` : ''}${element.min % 60 ? '30분' : ''})`;
				}
			});

			return str;
		},
		cmpyView() {
			if (this.bzmnCnfm.bzmnCnfmDocCnfmYn !== '미확인' && this.bzmnCnfm.bzmnCnfmDocUrl !== '') {
				return true;
			} else {
				return false;
			}
		},
		cmpyCheck() {
			//console.log('1');
			if (!this.bzmnCnfmChkBtnClk && ((this.bzmnCnfm.bzmnCnfmDocCnfmYn === '미확인' && this.bzmnCnfm.bzmnCnfmDocUrl !== '') || this.bzmnCnfm.bzmnCnfmDocCnfmYn === '정보조회완료')) {
				return true;
			}
			if (this.bzmnCnfmChkBtnClk && this.bzmnCnfm.bzmnCnfmDocCnfmYn === '미확인' && this.bzmnCnfm.bzmnCnfmDocUrl !== '') {
				return true;
			} else if (this.bzmnCnfmChkBtnClk && this.bzmnCnfm.bzmnCnfmDocCnfmYn === '정보조회완료') {
				return true;
			} else {
				return false;
			}
		},
		cmpyCheck1() {
			if (!this.bzmnCnfmChkBtnClk && ((this.bzmnCnfm.bzmnCnfmDocCnfmYn === '미확인' && this.bzmnCnfm.bzmnCnfmDocUrl !== '') || this.bzmnCnfm.bzmnCnfmDocCnfmYn === '정보조회완료')) {
				return true;
			} else {
				return false;
			}
		},
		cmpyCheck2() {
			if (this.bzmnCnfmChkBtnClk && this.bzmnCnfm.bzmnCnfmDocCnfmYn === '미확인' && this.bzmnCnfm.bzmnCnfmDocUrl !== '') {
				return true;
			} else {
				return false;
			}
		},
		cmpyCheck3() {
			if (this.bzmnCnfmChkBtnClk && this.bzmnCnfm.bzmnCnfmDocCnfmYn === '정보조회완료') {
				return true;
			} else {
				return false;
			}
		},
	},
	created() {
		this.isLoginCheck();

		this.getGtCtgrList('1405');
		this.getGtCtgrList('1407');
		this.getGtCtgrList('1402');
		this.getGtCtgrList('1406');
		this.getGtCtgrList('1404');

		//console.log(this.spaceRsvtStatusDcds);

		if (this.$route.params.spaceId) this.spaceId = this.$route.params.spaceId;
		this.params.spaceId = this.spaceId;
		if (this.$route.params.roomId) this.roomId = this.$route.params.roomId;
		this.params.roomId = this.roomId;

		if (this.roomId > 0) {
			this.getRoomInfo();
			this.getSpaceRoomList();
		}
	},
	methods: {
		getGtCtgrList(dcd) {
			//소상공인컨텐츠 카테고리 리스트 불러오기
			this.$store.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, { dcd: dcd, masterYn: 'N' }).then((res) => {
				const items = getCheckItems(res);
				// console.log(items);
				// this.spaceRsvtStatusDcds = items;
				//return items;
				if (dcd === '1405') {
					this.spaceRsvtStatusDcds = items;
				} else if (dcd === '1407') {
					this.spaceRsvtPurposDcds = items;
				} else if (dcd === '1402') {
					this.spaceTypeDcds = items;
				} else if (dcd === '1406') {
					this.spaceRsvtManprdClsDcds = items;
				} else if (dcd === '1404') {
					this.spaceRoomItemDcds = items;
				}
			});

			//return [];
		},
		popClose() {
			this.reservSbCheck.show = false;
		},
		showReservSbCheckModal() {
			this.reservSbCheck.show = true;
		},
		isLoginCheck() {
			if (!getTokenByKey('auth_token')) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: '회원가입 및 로그인이 필요합니다.',
					html: true,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
				});
				return false;
			}
		},
		getRoomInfo() {
			const param = { roomId: this.roomId };
			this.$store
				.dispatch(`space/${ACT_GET_SPACE_ROOM_INFO}`, param)
				.then((res) => {
					if (lengthCheck(res)) {
						this.roomInfo = getItem(res);
					}
				})
				.catch((e) => {
					console.error(e);
					this.roomId = 0;
				});
		},
		getSpaceRoomList() {
			this.$store
				.dispatch(`space/${ACT_GET_SPACE_ROOM_LIST}`, {
					pageNo: 1,
					pageSize: 1000,
					spaceId: this.spaceId || 0,
					roomNm: '',
				})
				.then((res) => {
					if (lengthCheck(res)) {
						this.roomList = getItems(res);
					} else {
						this.roomList = [];
					}
				})
				.catch((e) => {
					console.error(e);
					this.roomList = [];
				});
		},
		getRsvtCmpyValidation() {
			this.bzmnCnfmChkBtnClk = true;
			this.$store
				.dispatch(`space/${ACT_GET_SPACE_RSVT_CMPY_CHECK}`, {
					brNo: this.params.brno,
					cmpyNm: this.params.cmpyNm,
				})
				.then((res) => {
					if (lengthCheck(res)) {
						const item = getItem(res);
						this.bzmnCnfmDocCnfmYn = '정보조회완료';
						const rsvtStateParam = {
							brno: this.params.brno,
							cmpyNm: this.params.cmpyNm,
							bzmnCnfmDocCnfmYn: '정보조회완료',
							bzmnCnfmDocUrl: '',
							bzmnCnfmDocNm: '',
							issueDate: this.timestampToDateFormat(item.issueDate),
							periodStrtDate: this.timestampToDateFormat(item.periodStrtDate),
							periodEndDate: this.timestampToDateFormat(item.periodEndDate),
						};
						this.$store.commit(`space/${MUT_SET_SPACE_RSVT_BZMNCNFM}`, rsvtStateParam);
					} else {
						this.bzmnCnfmDocCnfmYn = '미확인';
						const rsvtStateParam = {
							brno: this.params.brno,
							cmpyNm: this.params.cmpyNm,
							bzmnCnfmDocCnfmYn: '미확인',
							bzmnCnfmDocUrl: '',
							bzmnCnfmDocNm: '',
							issueDate: '',
							periodStrtDate: '',
							periodEndDate: '',
						};
						this.$store.commit(`space/${MUT_SET_SPACE_RSVT_BZMNCNFM}`, rsvtStateParam);
						this.reservSbCheck.show = true;
					}
				})
				.catch((e) => {
					console.error(e);
					this.reservedTms = [];
				});
		},
		vstrCntChange(event) {
			if (this.roomInfo.maxRsvtUsrcnt < event.target.value) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: `방문자수는 ${this.roomInfo.maxRsvtUsrcnt}명을 넘을 수 없습니다.` });
				this.params.vstrCnt = this.roomInfo.maxRsvtUsrcnt;
			}
		},
		clickSave() {
			if (this.validate()) {
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
					title: `<p style='text-align: left'>${this.roomInfo.spaceNm}<br/>${this.roomInfo.roomNm}<br/>${this.rsvtDayString}<br/>${this.rsvtTimesString}<br/>예약을 신청하시겠습니까?`,
					html: true,
					showCancelButton: true,
					yesfunc: this.saveRsvtInfo,
					nofunc: () => {
						this.isLoading = false;
					},
				});
			} else {
				this.isLoading = false;
			}
		},
		validate() {
			let msg = '';
			let result = false;

			const query = 'input[name="rsvtSelectedItems"]:checked';
			const selectedEls = document.querySelectorAll(query);

			if (!this.params.rsvtUserNm) {
				msg = '예약자명을 입력해주세요.';
			} else if (!this.params.cnctelNo) {
				msg = '연락처를 입력해주세요.';
			} else if (!this.params.email) {
				msg = '이메일을 입력해주세요.';
			} else if (!this.params.cmpyNm) {
				msg = '기업명을 입력해주세요.';
			} else if (!this.params.brno) {
				msg = '사업자번호를 입력해주세요.';
			} else if (this.params.bzmnCnfmDocCnfmYn == '미확인' && this.params.bzmnCnfmDocUrl == '') {
				msg = '중소기업(소상공인) 확인 및 소상공인 확인서를 업로드 해주세요.';
			} else if (!this.params.rsvtDt) {
				msg = '예약일자를 선택해주세요.';
			} else if (!this.params.rsvtType) {
				msg = '예약방법을 선택해주세요.';
			} else if (!this.params.manprdClsDcd) {
				msg = '제품분류를 선택해주세요.';
			} else if (!this.params.rprsManprdNm) {
				msg = '대표제품명을 입력해주세요.';
			} else if (!this.params.rsvtPurposDcd) {
				msg = '예약목적을 선택해주세요.';
			} else if (!this.params.vstrCnt > 0) {
				msg = '방문자 수를 입력해주세요.';
			} else if (!selectedEls.length > 0) {
				msg = '대여장비를 선택해주세요.';
			} else if (!this.params.spaceId > 0) {
				msg = '공간을 선택해주세요.';
			} else if (!this.params.roomId > 0) {
				msg = '룸을 선택해주세요.';
			} else {
				result = true;
			}

			if (!result) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: msg });
			}
			return result;
		},
		saveRsvtInfo() {
			const query = 'input[name="rsvtSelectedItems"]:checked';
			const selectedEls = document.querySelectorAll(query);

			// 선택된 목록에서 value 찾기
			let result = '';
			selectedEls.forEach((el) => {
				if (result === '') {
					result = el.value;
				} else {
					result += ',' + el.value;
				}
			});
			this.params.itemDcdList = result;

			this.params.rsvtStsDcd = '1405001'; // 최초 생성시 신청 스테이터스 부여

			this.$store
				.dispatch(`space/${ACT_INSERT_SPACE_RSVT_INFO}`, this.params)
				.then((res) => {
					if (isSuccess(res)) {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: `예약이 등록되었습니다.`,
							yesfunc: () => {
								this.goList();
							},
						});
					} else {
						// this.isLoading = false;
						const result = getResult(res);
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: result.message });
					}
				})
				.catch((e) => {
					console.error(e);
					// this.isLoading = false;
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '에러가 발생하였습니다.' });
				});
		},
		goList() {
			this.$router.push(`/space/${this.spaceId}?tab=reserv`);
		},
		timestampToDateFormat(regDt) {
			return timestampToDateFormat(regDt, 'yyyy-MM-dd');
		},
	},
};
</script>
